<template>
  <div class="loddingbox">
    <div
      class="loddinginner"
      v-loading="true"
      element-loading-text="正在登录中..."
      element-loading-spinner="el-icon-loading"
    >
      <div class="lodding"></div>
    </div>
  </div>
</template>

<script>
import { scanLogin } from "@/api/login.js";
export default {
  data() {
    return {
      code: "",
    };
  },

  mounted() {
    const code = this.$route.query.code;
    this.code = code;
    this.scanlogin()
  },
	computed:{
		membersWechatScanId:{
			get(){
				return this.$store.state.membersWechatScanId
			},
			set(v){
				this.$store.commit('setMembersWechatScanId',v)
			}
		}
	},

  methods: {
    scanlogin() {
      var that = this
      const params = { wxcode: this.code, login_type: "scan" };
      console.log('params',params);
      scanLogin(params).then((res) => {
				if(res.data.is_bind){

             //登录成功
              const token = res.data.token;
              that.$store.commit('setToken',token)
              
					that.$router.push('/')
				}else{
					that.membersWechatScanId = res.data.members_wechat_scan_id
					that.$router.push('/bindtelphone')
				}

			});

    },
  },
};
</script>

<style lang="scss" scoped>
.loddingbox {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .loddinginner {
    height: 200px;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .lodding {
      height: 100px;
      width: 100px;
      background: url(~@/assets/scanloadding.png);
    }
  }
}
</style>